import { Knowledge, KnowledgeUpdatePayload } from '@/typings/domain';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const updateKnowledge = async (payload: KnowledgeUpdatePayload): Promise<Knowledge> => {
      const id = payload.id;

      delete payload.id;

      if (!payload.subcategory_id)
        delete payload.subcategory_id;

      return axios
        .put(`${MAIN_API_HOST}/knowledge/${id}`, payload)
          .then(result => {
            return result.data
          })
  }

export default updateKnowledge;
