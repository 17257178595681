import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { ISuccessResponse } from '@/api/types';

const deleteUser = async (userId: number): Promise<ISuccessResponse<1>> =>
  await axios.post(`${MAIN_API_HOST}/users/${userId}/deactivate`);

const removeUser = async (userId: number): Promise<ISuccessResponse<1>> =>
  await axios.get(`${MAIN_API_HOST}/users/destroy/${userId}`);

export { removeUser }

export default deleteUser;
