import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HEADERS } from '@/services/helpers/constants';
import GeneralModule from '@/store/modules/general/GeneralModule';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';
import Cookies from 'js-cookie';
import router from '@/router';
import UsersModule from '@/store/modules/users/UsersModule';

export function configureHttpClient() {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.headers[HEADERS.AUTH_HEADER] = 'Bearer ' + Cookies.get(HEADERS.AUTH_HEADER);
      return config;
    },
    function(error) {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    function(response: AxiosResponse) {
      return response;
    },
    function(error: AxiosError) {
      const errorCode = ((error || {}).response || {}).data.error || 'Errors.Global_Error';
      
      if (error.message && error.message.includes('401')) {
        Notify.error(i18n.t('Логин и пароль неверны').toString());
        if(UsersModule.currentUser)
        {
          Cookies.remove(HEADERS.AUTH_HEADER);
          return router.push({ path: '/login' });
        }
      } else if (error.message && error.message.includes('422')) {
        if(error.response.data['users.0.email'][0] === 'The users.0.email has already been taken.')
        {
          Notify.error('Пользователь уже зарегистрирован')
        }

        // if(error.response.data?.name[0] === 'The selected email is invalid.')
        // {
        //   Notify.error('Пользователь не найден')
        // }
        if(error.response.data?.email[0] === 'The selected email is invalid.')
        {
          Notify.error('Пользователь не найден')
        }
        if(error.response.data?.name[0] === 'Parametr name must be unique!')
        {
          Notify.error('Значение должно быть уникальным')
        }
        else
        {
          Notify.error(i18n.t('Errors.User_Already_Exist').toString());
        }

      } else {
        Notify.error(i18n.t(errorCode).toString());
      }
      return Promise.reject(error);
    },
  );
}
