import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=32991ebe&scoped=true"
import script from "./Icon.vue?vue&type=script&lang=ts"
export * from "./Icon.vue?vue&type=script&lang=ts"
import style0 from "./Icon.vue?vue&type=style&index=0&id=32991ebe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32991ebe",
  null
  
)

export default component.exports