import createCategories from '@/api/categories/actions/createCategories.action';
import deleteCategory	from '@/api/categories/actions/deleteCategory.action';
import updateCategory 	from '@/api/categories/actions/updateCategory.action';
import getCategories 	from '@/api/categories/actions/getCategories.action';
import moveCategory     from '@/api/categories/actions/moveCategory.action';
import getCategory 		from '@/api/categories/actions/getCategory.action';
const categories = {
    createCategories,
    getCategory,
    getCategories,
    deleteCategory,
    updateCategory,
	moveCategory,
};

export default categories;
