import { Route } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import Cookies from 'js-cookie';
import { HEADERS, MAIN_API_HOST } from '@/services/helpers/constants';
import axios from 'axios';
import Notify from '@/services/helpers/notify';

function shouldBeHashAllowed(to: Route, from: Route, next: Function) {

    const checkHash = () => axios.get(`${MAIN_API_HOST}/hash/check?`, {params: {hash: to.hash.slice(1,to.hash.length)}});

    const aToken = Cookies.get(HEADERS.AUTH_HEADER);
    checkHash().then((data) => 
    {
        const response = data.data as {success: boolean}
        if (!aToken || aToken.length < 2)
        {
            if (response.success)
            {
                next();
            }
            else
            {
                Notify.error('Недействительный токен')
                next({
                    path: '/login',
                    replace: true,
                })
            }
        }
        else
        {
            next({
                    path: ROUTES.HOME,
                    replace: true,
                });
        }
    });
}

export default shouldBeHashAllowed;
