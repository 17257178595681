
import { Component, Vue, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import PlusButtonIcon from '@/assets/images/plus-button.svg?inline';
import PlusButtonBigIcon from '@/assets/images/plus-button-big.svg?inline';
import ContextMenuLink from '../context-menu/items/ContextMenuLink.vue';
import UsersModule from '@/store/modules/users/UsersModule';
import GeneralModule from '@/store/modules/general/GeneralModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';

@Component({
  name: 'PlusButton',
  components: {
    PlusButtonIcon,
    PlusButtonBigIcon,
    Dropdown,
    ContextMenuLink,
  },
})
export default class PlusButton extends Vue {
  @Prop({ default: false }) floating: boolean;
  public expanded = false;

  get curentUsers() {
    return UsersModule.currentUser;
  }

  get isAdmin() {
    return GeneralModule.isAdmin
  }

  get hasAnyPermission(): boolean {
    const permissions = this.curentUsers?.permitions;
    if (!permissions) return false;

    return Object.values(permissions).some((permission) =>
      typeof permission === 'object'
        ? Object.values(permission).includes(true)
        : permission === true,
    );
  }

  canManage(sectionName: string) {
    if (sectionName === 'knowledge') {
      return (
        !UsersModule.currentUser.admin &&
        UsersModule.currentUser.permitions.knowledge.manage) ||
        UsersModule.currentUser.admin ||
        (UsersModule.currentUser.admin &&
          !UsersModule.currentUser.permitions.knowledge.manage)
    }
    // if(sectionName === 'projects')
    // {

    // }
    if (sectionName === 'team') {
      return this.curentUsers.permitions.team.invite || this.curentUsers.admin;
    }
  }

  get knowledgePermission() {
    return TeamsModule.permissionStatus
  }
}
