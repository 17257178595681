import auth from '@/api/auth/auth.api';
import invite from '@/api/invite/invite.api';
import user from '@/api/user/user.api';
import knowledge from '@/api/knowledge/knowledge.api';
import categories from '@/api/categories/categories.api';
import roles from '@/api/roles/roles.api';
import subCategories from '@/api/subcategories/subcategories.api';
import tags from '@/api/tags/tags.api';
import tagsGroups from '@/api/tagsGroups/tagsGroups.api';
import teams from '@/api/teams/teams.api'
import settings from '@/api/settings/settings.api';
import specialisations from '@/api/specialisations/specialisations.api'
import projects from '@/api/projects/projects.api';
import settingsTeams from '@/api/settingsTeams/settingsTeams.api';
import recovery from '@/api/recovery/recovery.api';

const ApiClient = {
	auth,
	invite,
	user,
	projects,
	knowledge,
	roles,
	categories,
	subCategories,
	specialisations,
	tags,
	tagsGroups,
	settings,
	settingsTeams,
	teams,
	recovery,
};

export default ApiClient;
