import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

type AuthResponse = {
  name: string,
  id: number
};

const getEditInfo = async (id: number): Promise<AuthResponse> =>
  axios.get(`${MAIN_API_HOST}/knowledge/editingcheck/${id}`)
    .then(result => result.data);

const cancelEditing = async (id: number): Promise<AuthResponse> =>
  axios.get(`${MAIN_API_HOST}/knowledge/editingcancel/${id}`)
    .then(result => result.data);

export { cancelEditing, getEditInfo }
