export enum KeyCodes {
  Escape = 'Escape',
  Enter = 'Enter',
}

export enum BrowserEvents {
  click = 'click',
}

export enum HEADERS {
  AUTH_HEADER = 'Authorization',
  HAS_LINK = 'Has-Link'
}

export enum RequestResult {
  S = 'Success',
  F = 'Fail',
}

export const MAIN_API_HOST = process.env.VUE_APP_API_URL || 'https://dev.senq.ru/api';

export const REQUEST_DEBOUNCE_TIME = 250;
