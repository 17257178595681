import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const moveCategory = async (ids: string, spec: number) => {
	return await axios.post(`${MAIN_API_HOST}/knowledge/moveCategory/`, {
		ids: ids,
		spec: spec,
	})
}

export default moveCategory;
